import React from 'react';
import { BsEyeSlash, BsEye } from 'react-icons/bs';

import './DSInput.scss';

const DSInput = (props) => {
    const { type, name, value, handleChange, handleBlur, label, placeholder, touched, errors, disabled,
        revealPassword, onRevealPasswordClick, hideRevealIcon = false, className, style, readOnly, step } = props;

    const disabledClassName = disabled ? 'non-editable' : '';
    const validationFaild = touched && errors && touched[name] && errors[name];

    return (
        <div className={`form-group ${className ? className : ''} ${disabledClassName} ${validationFaild ? 'field-err' : ''}
        `} style={style}>
            <label htmlFor={name} >{label}</label>
            {
                !hideRevealIcon && type === 'password' && (
                    <div style={{ cursor: 'pointer' }} className='reveal-password' onClick={onRevealPasswordClick}>
                        {revealPassword ? <BsEye className='eye' /> : <BsEyeSlash className='eye-slash' />}
                    </div>
                )
            }
            <input
                id={name}
                name={name}
                type={revealPassword ? 'text' : type}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={placeholder}
                value={value}
                step={step}
                disabled={disabled}
                readOnly={readOnly} />
            {validationFaild ? <div className='form-error'>{errors[name]}</div> : null}
        </div>
    )
};

export default DSInput;