import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import APIService from '../../../services/ApiService';
import GraphQLService from '../../../services/GraphQLService';
import { FORGOT_PASSWORD_QUERY } from '../GraphQLQueries';
import useCancelationTokenEffect from '../../../hooks/useCancelationTokenEffect.js';
import DSInput from '../../Shared/Inputs/DSInput';
import DSButton from '../../Shared/Buttons/DSButton';
import { CSSTransition } from 'react-transition-group';
import Spinner from '../../Shared/Spinner/Spinner';

const RestoreSchema = Yup.object().shape({
    username: Yup.string()
        .min(6, 'Must contain at least 6 characters')
        .required('Required')
});

const ForgotPassword = (props) => {
    useCancelationTokenEffect(APIService);
    useCancelationTokenEffect(GraphQLService);
    const navigateTo = useNavigate();

    const [passwordState, setPasswordState] = React.useState({
        isLoading: false,
        APIError: null
    });

    const { isLoading, APIError } = passwordState;

    const [isRestoreSuccess, setIsRestoreSuccess] = React.useState(false);

    const handleRestorePass = async (formData) => {
        const { username } = formData;
        setPasswordState({
            ...passwordState,
            isLoading: true,
        });

        const variables = {
            username
        };

        const response = await GraphQLService.fetch('https://passport-api.services.r2net.com/auth', {
            query: FORGOT_PASSWORD_QUERY,
            variables
        });

        if (response.forgotPassword.status) {
            setPasswordState({
                ...passwordState,
                isLoading: false,
            });

            setIsRestoreSuccess(true);
        } else {
            setPasswordState({
                isLoading: false,
                APIError: "Failed to restore password"
            });
        }
    };

    const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
        initialValues: {
            username: ''
        },
        validationSchema: RestoreSchema,
        onSubmit: handleRestorePass
    });

    return (
        <div className='forgot-password-form-container'>
            <div className="fp-title">Forgot Password?</div>
            {!isRestoreSuccess && <div className="fp-content">
                <div>
                    In order reset your password, please enter your passport
                    username and an email associated  will be sent.
                </div>
                <form onSubmit={handleSubmit}>
                <DSInput
                        handleChange={handleChange}
                        type='text'
                        label='Username'
                        name='username'
                        value={values.username}
                        handleBlur={handleBlur}
                        placeholder='Your Username'
                        errors={errors}
                        touched={touched} />

                    <div className='btn-container '>
                        <DSButton
                            className={`restore-btn ${isLoading && 'disabled'}`}
                            text={isLoading ? '' : 'CONTINUE'}
                            type='submit'
                            disabled={isLoading}>

                            <CSSTransition
                                in={isLoading}
                                classNames='login-anim'
                                timeout={5000}
                                unmountOnExit>
                                <div className='spinner-container'>
                                    {isLoading && <Spinner />}
                                </div>
                            </CSSTransition>
                        </DSButton>
                    </div>
                    <div className='error-box'>
                        <span className='err-text'>{APIError}</span>
                    </div>
                </form>
            </div>}
            {isRestoreSuccess && <div className="fp-content">
                <div className="fp-success-text">
                    A reset password link was emailed to you. Please check your inbox. <span className="fp-link" onClick={() => navigateTo("/auth/login")}>Back to login page</span>
                </div>
                <div className="fp-fail-text">
                    If you have any issues, please email us:<span className="fp-email">r2net@r2net.com</span>
                </div>
            </div>}

        </div>
    );
}

export default ForgotPassword;

