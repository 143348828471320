import React from 'react';
import * as Yup from 'yup';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { produce } from 'immer';
import APIService from '../../../services/ApiService';
import GraphQLService from '../../../services/GraphQLService';
import useCancelationTokenEffect from '../../../hooks/useCancelationTokenEffect.js';
import { LOGIN_QUERY } from '../GraphQLQueries';
import { userState } from '../../../recoil/Auth/atoms';
import { isVisibleState } from '../../../recoil/DesignYourGallery/atoms';
import DSButton from '../../Shared/Buttons/DSButton';
import { useFormik } from 'formik';
import Spinner from '../../Shared/Spinner/Spinner';
import { CSSTransition } from 'react-transition-group';
import DSInput from '../../Shared/Inputs/DSInput';
import { UserTypes } from '../../../config/Users';
import CircleCheckEmpty from '../../../assets/Images/circle_checkbox_default.svg';
import CircleCheck from '../../../assets/Images/circle_checkbox_selected.svg';

import './Login.scss';

const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string()
        .min(6, 'Must contain at least 6 characters')
        .required('Required')
});

const Login = ({ mockHandleSubmit }) => {
    useCancelationTokenEffect(APIService);
    useCancelationTokenEffect(GraphQLService);
    const navigateTo = useNavigate();
    const [localStorageCurrency, setLocalStorageCurrency] = useLocalStorage('currency', null);
    const [user, setUser] = useRecoilState(userState);
    const setDesignYourGalleryVisibleState = useSetRecoilState(isVisibleState);
    const { loginLoading, APIError } = user;
    const [rememberMe, setRememberMe] = React.useState(false);
    const [revealPassword, setRevealPassword] = React.useState(false);

    const handleRemeberMeClicked = () => {
        setRememberMe(prevState => !prevState);
    }

    const handleRevealPasswordClicked = () => {
        setRevealPassword(prevState => !prevState);
    }

    const handleLogin = async (formData) => {
        setUser(produce(draftState => {
            draftState.loginLoading = true
            draftState.APIError = null
        }));

        const { username, password } = formData;

        let variables = {
            username: username.trim(),
            password: password.trim(),
            loginAsAppId: 'dStore'
        };

        try {         
            const response = await GraphQLService.fetch('https://passport-api.services.r2net.com/auth', {
                query: LOGIN_QUERY,
                variables
            });

            const { token, status } = response.login;
    
            if (status && token) {
                const saveTokenSucceeded = await APIService.post('/Login/SaveAuth', { token: token });            
                
                if (saveTokenSucceeded === "OK") {
                    const authenticateUserDetails = await APIService.get('/Login/GetAutenticateUser');
                    
                    authenticateUserDetails && setUserDetailsToRecoilState(authenticateUserDetails);   
                }
            } else {
                setUser(produce(draftState => {
                    draftState.loginLoading = false
                    draftState.APIError = 'Login server error, please contact administrators.'
                }));
            }
        } catch (err) {
            setUser(produce(draftState => {
                draftState.loginLoading = false
                draftState.APIError = 'Somthing went wrong, please contact administrators!'
            }));
            console.log(err);
        }
    };

    const setUserDetailsToRecoilState = (authenticateUserDetails) => {
        const { UserType, Roles, StoreCountry } = authenticateUserDetails;

        if (UserType === UserTypes.STORE) {

            setUser(produce(draftState => {
                draftState.loginLoading = false
                draftState.isAuthenticated = true
                draftState.userRoles = Roles
                draftState.isStoreRegistered = authenticateUserDetails.IsRegistered
                draftState.userCompanyName = authenticateUserDetails.CompanyName
                draftState.markupLevel = authenticateUserDetails.MarkupLevel
                draftState.storeCountry = StoreCountry
                draftState.currencySymbol = !localStorageCurrency ? '$' : localStorageCurrency.symbol
                draftState.currencyRate = !localStorageCurrency ? '1.0000' : localStorageCurrency.rate
                draftState.currencyCode = !localStorageCurrency ? 'USD' : localStorageCurrency.code
            }));

            if (!authenticateUserDetails.IsRegistered) {
                setDesignYourGalleryVisibleState(true);
                navigateTo('/onBoarding#account-details');
            } else {
                navigateTo('/');
            }
        } else if(UserType === UserTypes.CUSTOMER) { // UserTypes.CUSTOMER
            setUser(produce(draftState => {
                draftState.loginLoading = false
                draftState.isAuthenticated = true
                draftState.userRoles = Roles
                draftState.userCompanyName = authenticateUserDetails.CompanyName
                draftState.markupLevel = authenticateUserDetails.MarkupLevel
                draftState.storeCountry = StoreCountry
                draftState.currencySymbol = !localStorageCurrency ? '$' : localStorageCurrency.symbol
                draftState.currencyRate = !localStorageCurrency ? '1.0000' : localStorageCurrency.rate
                draftState.currencyCode = !localStorageCurrency ? 'USD' : localStorageCurrency.code
            }));

            navigateTo('/');
        } else if (UserType === UserTypes.ADMIN_INVENTORY) {
            setUser(produce(draftState => {
                draftState.loginLoading = false
                draftState.isAuthenticated = true
                draftState.userRoles = Roles
            }));
            navigateTo('/export-inventory');
        }
    }

    const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: LoginSchema,
        onSubmit: handleLogin
    });

    return (
        <div className='login-form-container'>
            <div className='form-header'>
                <span className='welcome'>Welcome To</span>
                <span className='diamonds-vlds'>DSTORE</span>
                <p>Sign in by entering the informations below</p>
            </div>
            <form onSubmit={mockHandleSubmit ? mockHandleSubmit : handleSubmit}>
                <DSInput
                    handleChange={handleChange}
                    type='text'
                    label='Username'
                    name='username'
                    value={values.username}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched} />
                <DSInput
                    handleChange={handleChange}
                    type='password'
                    label='Password'
                    name='password'
                    value={values.password}
                    handleBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    revealPassword={revealPassword}
                    onRevealPasswordClick={handleRevealPasswordClicked} />

                <div className='rememberme-forget-password-sec'>
                    <div className='form-group remember-me'>
                        <div className='remember-me-icon' onClick={handleRemeberMeClicked}>
                            {rememberMe ? <img src={CircleCheck} alt='c' /> : <img className='empty-circle-icon' src={CircleCheckEmpty} alt='c' />}
                        </div>
                        <label>Remember Me</label>
                    </div>
                    <Link className='forgot-password' to='/auth/forgotpassword'>Forgot Password</Link>
                </div>

                <div className='error-box'>
                    <span className='err-text'>{APIError}</span>
                </div>

                <div className='btn-container '>
                    <DSButton
                        className={`login-btn ${loginLoading && 'disabled'}`}
                        text={loginLoading ? '' : 'SIGN IN'}
                        type='submit'
                        disabled={loginLoading}>

                        <CSSTransition
                            in={loginLoading}
                            classNames='login-anim'
                            timeout={5000}
                            unmountOnExit>
                            <div className='spinner-container'>
                                {loginLoading && <Spinner />}
                            </div>
                        </CSSTransition>
                    </DSButton>
                </div>
            </form>
        </div>
    )
};

export default Login;
