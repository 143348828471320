import React, {Suspense} from 'react';
import { Outlet } from 'react-router-dom';
import { Roles } from './Roles';
import Login from '../components/Auth/Login/Login';
import ForgotPassword from '../components/Auth/ForgotPassword/ForgotPassword';
import PageNotFound from '../components/PageNotFound/PageNotFound';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import DSLoader from '../components/Shared/Loader/DSLoader';

const Loadable = (Component) => props => (
    <Suspense fallback={<DSLoader loading={true}/>}>
      <Component {...props} />
    </Suspense>
);

const AsyncAuthenticatedApp = Loadable(React.lazy(() => import(/*webpackChunkName: "authenticatedApp"*/ '../components/Layouts/AuthenticatedApp/AuthenticatedApp')));
const AsyncUnauthenticatedApp = Loadable(React.lazy(() => import(/*webpackChunkName: "unauthenticatedApp"*/ '../components/Layouts/UnauthenticatedApp/UnauthenticatedApp')));
const AsyncGalleryPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncGalleryPage"*/ '../components/Gallery/GalleryPage/GalleryPage')));
const AsyncAdministration = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncAdministration"*/ '../components/Administration/Administration')));
const AsyncOrdersPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncOrdersPage"*/ '../components/OrdersPage/OrdersPage')));
const AsyncMyAccountPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncMyAccountPage"*/ '../components/MyAccountPage/MyAccountPage')));
const AsyncCheckout = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncCheckout"*/ '../components/Checkout/Checkout')));
const AsyncCheckoutGratitued = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncCheckoutGratitued"*/ '../components/Checkout/CheckoutGratitued/CheckoutGratitued')));
const AsyncOnBoardingPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncOnBoardingPage"*/ '../components/OnBoarding/OnBoardingPage/OnBoardingPage')));
const AsyncOnBoardingGratitude = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncOnBoardingGratitude"*/ '../components/OnBoarding/OnBoardingGratitude/OnBoardingGratitude')));
const AsyncOnlineCustomers = Loadable(React.lazy(() => import(/*webpackChunkName: "AsyncOnlineCustomers"*/ '../components/MyAccountPage/OnlineCustomers/OnlineCustomers')));
const AsyncExportInventory = Loadable(React.lazy(() => import(/*webpackChunkName: "AsyncExportInventory"*/ '../components/Administration/Tools')));

// This structure of role-base routing(RBAC) inspired from:
// https://medium.com/javascript-in-plain-english/role-based-authorization-role-based-access-control-v-2-in-react-js-cb958e338f4b
export default [
    {
        path: '/',
        isPrivate: true,
        element: <AsyncAuthenticatedApp />,
        children: [
            { path: '/', element: <AsyncGalleryPage /> },
            { path: '/orders/:page', element: <AsyncOrdersPage /> },
            { path: '/Admin', element: <AsyncAdministration />, permission: [Roles.ADMIN] },
            { path: '/my-account/:page', element: <AsyncMyAccountPage />, permission: [Roles.ADMIN] },
            { path: '/online-customers', element: <AsyncOnlineCustomers />, permission: [Roles.ADMIN] },
            {
                path: '/checkout',
                element: <Outlet />,
                children: [
                    { path: '/', element: <AsyncCheckout /> },
                    { path: '/complete', element: <AsyncCheckoutGratitued /> },
                ],
                permission: [Roles.ADMIN]
            },
            { path: '/auth-error', element: <ErrorPage /> }
        ],
        permission: [Roles.ADMIN, Roles.ONLINE_CUSTOMER]
    },
    { 
        path: '/export-inventory', 
        isPrivate: true,
        element: <AsyncExportInventory />,
        permission: ['admin-inventory'] 
    },
    {
        path: '/onBoarding',
        isPrivate: true,
        element: <Outlet />,
        children: [
            { path: '/', element: <AsyncOnBoardingPage /> },
            { path: '/complete', element: <AsyncOnBoardingGratitude /> }
        ],
        permission: [Roles.ADMIN]
    },
    {
        path: '/auth',
        element: <AsyncUnauthenticatedApp />,
        children: [
            { path: '/login', element: <Login /> },
            { path: '/register', element: <div>Register Mode</div> },
            { path: '/forgotpassword', element: <ForgotPassword /> }
        ]
    },
    { path: '/terms-and-conditions', element: <h1>Terms and Conditions Page</h1> },
    { path: '/privacy-policy', element: <h1>Privacy Policy Page</h1> },
    { path: '*', element: <PageNotFound /> }
];
