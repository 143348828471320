import { atom } from 'recoil';

export const userState = atom({
    key: 'userState',
    default: {
        isAuthenticated: false,
        userRoles: [],
        userCompanyName: '',
        isStoreRegistered: true,
        loginLoading: false,    
        APIError: null,
        markupLevel: null,
        currencyRate: '1.0000',
        currencySymbol: '$',
        currencyCode: '',
        storeCountry: '',
    }
});