import intersection from 'lodash.intersection';

function isArrayWithLength(arr) {
    return (Array.isArray(arr) && arr.length)
}

// This flater filter method inspired from: 
// https://gist.github.com/schuchard/a60daa25a1840d9371eae023c5be417a
export function getAllowedRoutesByRoles(userRoles, allRoutes) {
    return allRoutes.filter(route => {
        const keep = intersection(route.permission, userRoles).length > 0 ||
            !route.permission ||
            !isArrayWithLength(route.permission);

        if (keep && route.children) {
            route.children = getAllowedRoutesByRoles(userRoles, route.children);
        }
        return keep;
    });
}
