import { useEffect } from 'react';
import Axios from 'axios';

export default function useCancelationTokenEffect(serviceInstance) {
    
    useEffect(() => {
        let source; //= Axios.CancelToken.source();

        serviceInstance.service.interceptors.request.use(config => {

            if (!config.cancelToken) {
                source = Axios.CancelToken.source();
                config.cancelToken = source.token
            }

            return config;
        }, error => Promise.reject(error));

        return () => {
            source && source.cancel('cancel request called!');
        };
    }, [serviceInstance.service.interceptors.request]);
}