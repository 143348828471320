import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import './DSButton.scss';

// Theme for apllying our font family for all Buttons
const theme = createMuiTheme({
  typography: {
    fontFamily: 'proxima-nova'
  }
});


const DSButton = (props) => {

  const bgcolor = props.color === "primary" ? "--primary-color" : "--secondary-color";
  const textcolor = props.color === "primary" ? "#fff" : "#000";

    const useStyles = makeStyles((theme) => ({
      root: {
        width: '100%',
        color: textcolor,
        backgroundColor: 'var(' + bgcolor + ')',
        transition: 'all 200ms',
        '& > *': {
          margin: theme.spacing(1),
        },
        "&:hover": {
          backgroundColor: 'var(' + bgcolor + ') !important',
          //boxShadow: props.color === "primary" ? '0 0 5px rgba(33,33,33,0.6)' : '0 0 0',
          opacity: '0.95'
        }
      },
      ...props.styles
    }));

    const classes = useStyles();

    const {text, onClick, className, children} = props;

    return (
      // <button type="button" className={className} onClick={(e)=> onClick(e)}>{text}</button>
      <ThemeProvider theme={theme}>
        <Button
          title={text}
          className={`btn ${className ? className : classes.root}`}
          variant="contained"
          onClick={onClick}
          {...props}>
          {text}
          {children}
        </Button>
      </ThemeProvider>
    );
}

export default DSButton;    