import React from 'react';

import './Spinner.scss'

const Spinner = (props) => {

    const {style} = props;

    return (
        <div className='spinner' style={style}></div>
    )
};

export default Spinner;
