import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
class Service {
    
    constructor() {
        //const CancelToken = axios.CancelToken;
        let service = axios.create({
            baseURL: BASE_URL,
            headers: {
                'Content-type': 'application/json; charset=utf-8'
            },
            timeout: 60000,
            withCredentials: true
            //cancelToken: new CancelToken(function (cancel) {alert(5)}),  //// 1 min timeout
        });
        
        this.service = service;
    }

    handleError(path, params, err) {
        if (err && axios.isCancel(err)) { // Catch axios request cancelation
            console.log('Cancel request token', err.message)
        } else {
            if (err && err.response && err.response.status === 401) { // Unauthorized
                //TODO: Check if it can be improved
                console.log('user unautorized');
                window.location.href = '/auth/login';
            }
            else if (err && err.response && err.response.status === 403) { // Forbidden
                console.log('Forbidden user route/request');
            }
            else if (err && err.response && err.response.status === 404) { // Not Found
                console.log('Error 404');
            }
            else if (err && err.message && err.message.includes('timeout')){
                console.log(err.message);
            }
            else {
                var errorParams = params ? JSON.stringify(params) : "";
    
                // this.service.request({
                //     method: 'POST',
                //     url: "/Common/sendClientError",
                //     data: {
                //         parameters: errorParams,
                //         requestPath: path,
                //         error: JSON.stringify(err),
                //         stackTrace: JSON.stringify(err.stack)
                //     }
                // })
                
                console.log(`Had Issues getting to the backend, endpoint: ${path}, with data: ${errorParams}`);
                console.dir(err);
            }
            throw err;
        }
    }

    // async get(path, requestConfig, promiseTrackerArea) {
    async get(path, params, loaderTrackerArea) {
        try {
            // if we want to send params we need to supply params property to requestConfig
            // const response = await trackPromise(this.service.get(path, requestConfig), promiseTrackerArea);
            const response = await trackPromise(this.service.request({
                method: 'GET',
                url: path,
                params
            }), loaderTrackerArea);

            return response.data;
        } catch (err) {
            this.handleError(path, params, err);
        }
    }

    async post(path, payload, loaderTrackerArea) {
        try {
            // const response = await trackPromise(this.service.post(path, payload, requestConfig), promiseTrackerArea)
            const response = await trackPromise(this.service.request({
                method: 'POST',
                url: path,
                data: payload
            }), loaderTrackerArea);

            return response.data;
        } catch (err) {
            this.handleError(path,payload, err);
        }
    }

    async put(path, payload, loaderTrackerArea) {
        try {
            const response = await trackPromise(this.service.request({
                method: 'PUT',
                url: path,
                data: payload
            }), loaderTrackerArea);

            return response.data;
        } catch (err) {
            this.handleError(path,payload, err);
        }
    }

    async delete(path, payload, loaderTrackerArea) {
        try {
            // const response = await trackPromise(this.service.post(path, payload, requestConfig), promiseTrackerArea)
            const response = await trackPromise(this.service.request({
                method: 'DELETE',
                url: path,
                data: payload
            }), loaderTrackerArea);

            return response.data;
        } catch (err) {
            this.handleError(path, payload, err);
        }
    }

    async all(requests, loaderTrackerArea) {
        let requestArr = [];
        requests.forEach(request => {
            let currentRequest;
            const {url, params, method} = request;

            if (method === 'GET') {
                currentRequest = this.service.get(url, { params: params })
            } else {
                currentRequest = this.service.request({
                    method: method,
                    url: url,
                    data: params
                });
            }
            requestArr.push(currentRequest)
        });
        
        try {
            const values = await trackPromise(Promise.all(requestArr), loaderTrackerArea)

            return values;
        } catch (err) {
            this.handleError(null, null, err);
        }
    }
}

//export default Service;
export default new Service();