import React from 'react';
import APIService from '../services/ApiService';

export default function useErrorBoundries() {

  React.useEffect(() => {

    const logError = async (message, url, lineNumber, column, errorObj) => {
      try {

        const stackTrace = (errorObj && errorObj.stack) ? errorObj.stack.replace(/</g, "").replace(/>/g, "") : "";
        const messageToCheck = stackTrace ? stackTrace : message;

        // TODO: if we will see that line didn't help replace it with:
        // TODO: https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5 
        if (/Loading chunk [\d]+ failed/.test(message)) {
          window.location.reload();
        }

        if (!shouldSkipExceptions(messageToCheck) && url && url.indexOf('localhost') == -1) {
          const res = await APIService.post('/Common/SendClientError', {
            error: message,
            stackTrace: stackTrace,
            requestPath: url
          });
        }

      }
      catch (ex) { }

      //const msg = (errorObj && errorObj.stack) ? errorObj.stack : message;
      // throw new Error(msg);
      return true;
    }

    window.onerror = logError;

  }, []);

  function shouldSkipExceptions(message) {
    const msgArr = ["setSetsNum"];

    try {
      for (var i = 0; i < msgArr.length; i++) {
        if (message && message.indexOf(msgArr[i]) > -1)
          return true;
      }
    }
    catch (ex) { }

    return false;
  }

}