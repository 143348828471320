import React, { Suspense } from 'react';
import { Routes, Route, useNavigate, Navigate, useLocation, Outlet, useRoutes } from 'react-router-dom';
// import PrivateRoute from './components/Routes/PrivateRoute';
import { useRecoilState } from 'recoil';
import { userState } from './recoil/Auth/atoms';
import { produce } from 'immer';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import useLocalStorage from './hooks/useLocalStorage';
// import { Roles } from './config/Roles';
//import { RoutesByRole } from './config/RoutesConfig';
import APIService from './services/ApiService';
import DSLoader from './components/Shared/Loader/DSLoader';
// import Login from './components/Auth/Login/Login';
// import ForgotPassword from './components/Auth/ForgotPassword/ForgotPassword';
// import PageNotFound from './components/PageNotFound/PageNotFound';
// import ErrorPage from './components/ErrorPage/ErrorPage';
import useErrorBoundries from './hooks/useErrorBoundries';

import { getAllowedRoutesByRoles } from './utils/RouterUtils';
import PrivateRoutesConfig from './config/PrivateRoutesConfig';
import { UserTypes } from './config/Users';

import cloneDeep from 'lodash.clonedeep';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toast.scss';

// const Loadable = (Component) => props => (
//   <Suspense fallback={<DSLoader loading={true}/>}>
//     <Component {...props} />
//   </Suspense>
// );

// Lazy load components
// const AsyncAuthenticatedApp = Loadable(React.lazy(() => import(/*webpackChunkName: "authenticatedApp"*/ './components/Layouts/AuthenticatedApp/AuthenticatedApp')));
// const AsyncUnauthenticatedApp = Loadable(React.lazy(() => import(/*webpackChunkName: "unauthenticatedApp"*/ './components/Layouts/UnauthenticatedApp/UnauthenticatedApp')));
// const AsyncGalleryPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncGalleryPage"*/ './components/Gallery/GalleryPage/GalleryPage')));
// const AsyncAdministration = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncAdministration"*/ './components/Administration/Administration')));
// const AsyncOrdersPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncOrdersPage"*/ './components/OrdersPage/OrdersPage')));
// const AsyncMyAccountPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncMyAccountPage"*/ './components/MyAccountPage/MyAccountPage')));
// const AsyncCheckout = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncCheckout"*/ './components/Checkout/Checkout')));
// const AsyncCheckoutGratitued = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncCheckoutGratitued"*/ './components/Checkout/CheckoutGratitued/CheckoutGratitued')));
// const AsyncOnBoardingPage = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncOnBoardingPage"*/ './components/OnBoarding/OnBoardingPage/OnBoardingPage')));
// const AsyncOnBoardingGratitude = Loadable(React.lazy(() => import(/*webpackChunkName: "asyncOnBoardingGratitude"*/ './components/OnBoarding/OnBoardingGratitude/OnBoardingGratitude')));

function App() {
  useErrorBoundries();
  const navigateTo = useNavigate();
  const [user, setUser] = useRecoilState(userState);
  const [isCheckUserAuth, setIsCheckUserAuth] = React.useState(true);
  const [currencyRates, setCurrencyRates] = React.useState([]);
  const [localStorageCurrency, setLocalStorageCurrency] = useLocalStorage('currency', null);
  const { isAuthenticated, userRoles } = user;

  React.useEffect(() => {
    const verifyStoreAuthentication = async () => {

      try {
        const isStoreLoggedIn = await APIService.get('/Login/IsStoreLoggedIn'); //same as locals

        if (isStoreLoggedIn) {
          const authenticateUserDetails = await APIService.get('/Login/GetAutenticateUser');
          setUserDetailsToRecoilState(authenticateUserDetails);
        } else {
          navigateTo('/auth/login');
        }
      } catch (err) {
        setUser(produce(draftState => {
          draftState.APIError = 'Somthing went wrong, please contact administrators!'
        }));
        console.log(err);
      } finally {
        setIsCheckUserAuth(false)
      }
    }

    const setUserDetailsToRecoilState = (authenticateUserDetails) => {
      const { UserType, Roles, StoreCountry } = authenticateUserDetails;

      if (UserType === UserTypes.STORE) {
        setUser(produce(draftState => {
          draftState.loginLoading = false
          draftState.isAuthenticated = true
          draftState.userRoles = Roles
          draftState.isStoreRegistered = authenticateUserDetails.IsRegistered
          draftState.userCompanyName = authenticateUserDetails.CompanyName
          draftState.markupLevel = authenticateUserDetails.MarkupLevel
          draftState.storeCountry = StoreCountry
          draftState.currencySymbol = !localStorageCurrency ? '$' : localStorageCurrency.symbol
          draftState.currencyRate = !localStorageCurrency ? '1.0000' : localStorageCurrency.rate
          draftState.currencyCode = !localStorageCurrency ? 'USD' : localStorageCurrency.code
        }));
      } else if (UserType === UserTypes.CUSTOMER) { // UserTypes.CUSTOMER
        setUser(produce(draftState => {
          draftState.loginLoading = false
          draftState.isAuthenticated = true
          draftState.userRoles = Roles
          draftState.userCompanyName = authenticateUserDetails.CompanyName
          draftState.markupLevel = authenticateUserDetails.MarkupLevel
          draftState.storeCountry = StoreCountry
          draftState.currencySymbol = !localStorageCurrency ? '$' : localStorageCurrency.symbol
          draftState.currencyRate = !localStorageCurrency ? '1.0000' : localStorageCurrency.rate
          draftState.currencyCode = !localStorageCurrency ? 'USD' : localStorageCurrency.code
        }));
      } else if (UserType === UserTypes.ADMIN_INVENTORY) {
        setUser(produce(draftState => {
          draftState.loginLoading = false
          draftState.isAuthenticated = true
          draftState.userRoles = Roles
        }));
        navigateTo('/export-inventory');
      }
    }

    verifyStoreAuthentication();

  }, []);

  const getRoutesByRole = () => {
    let allowedRoutes;
    let allAppRoutesCopy = cloneDeep(PrivateRoutesConfig);

    if (isAuthenticated) {
      //debugger
      //const allowedRoutes = RoutesByRole[currentLogedInRole](isAuthenticated);
      allowedRoutes = getAllowedRoutesByRoles(userRoles, allAppRoutesCopy);
    } else {
      // Only public routes are populate
      allowedRoutes = allAppRoutesCopy.filter(route => !route.isPrivate);
    }
    // console.log('allowedRoutes: ', allowedRoutes)

    return allowedRoutes;
  };

  //console.log('PrivateRoutesConfig------------: ', PrivateRoutesConfig)

  // const routesCallback = React.useCallback(() => getRoutesByRole(), [isCheckUserAuth]);
  //const appRoutes = useRoutes(routesCallback());

  const appRoutes = useRoutes(getRoutesByRole());

  return (
    <div className='App'>
      <ToastContainer
        className='toast-wrapper'
        toastClassName='toast'
        bodyClassName='toast-body'
        position='top-center'
        transition={Zoom} />
      {
        !isCheckUserAuth ?
          (
            <>
              {appRoutes}
            </>
          )
          : <DSLoader />
      }
    </div>
  );
}

export default App;
