import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';

class GraphQLService {
    
    constructor() {
        let service = axios.create({
            headers: {
                'Content-type': 'application/json; charset=utf-8',
                'app-id': 'dStore'
            },
            timeout: 60000,
            withCredentials: true
        });
        
        this.service = service;
    }

    handleError(path, params, err) {
        if (err && axios.isCancel(err)) { // Catch axios request cancelation
            console.log('Cancel request token', err.message)
        } 
        else if (err && err.response && err.response.status !== 200) {
            const errorsArray = err.response.data.errors;
            console.log(`Had Issues getting to the GraphQL - Error Status-[${err.response.status}], endpoint: ${path}, message: ${JSON.stringify(errorsArray)}, `);
            console.dir(err);
        } else {
            const errorsArray = err;
            console.log(`Had Issues getting to the GraphQL - Error Status-[${err.response.status}], endpoint: ${path}, message: ${JSON.stringify(errorsArray)}, `);
            console.dir(err);
        }
        throw err;
    }

    async fetch(path, params, loaderTrackerArea = null) {
        try {            
            const response = await trackPromise(this.service.post(path, params), loaderTrackerArea);
            if(response && response.data.errors) {
                this.handleError(path, params, response.data.errors);
            }
            return response.data.data;
        } catch (err) {
            this.handleError(path, params, err);
        }
    }
}

export default new GraphQLService();