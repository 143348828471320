export const LOGIN_QUERY = `query ($password: String!, $username: String!, $loginAsAppId: String!) {
    login(password: $password, username: $username, loginAsAppId: $loginAsAppId) {
        token 
        status 
        username
    }
}`;

export const FORGOT_PASSWORD_QUERY = `query ($username: String!) {
    forgotPassword(username: $username) {
        status 
        message
    }
}`;