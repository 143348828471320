import React from 'react';
import ReactDOM from 'react-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import './DSLoader.scss';

const DMLoader = (props) => {

    // STATE
    const [loaderType, setLoaderType] = React.useState("Absolute");

    // REFS
    const loaderEl = React.useRef(null);
    const areaEl = React.useRef(null);

    // PROPS
    const { children, loading = false, withMasking = false, area, loaderSize, loaderColor } = props;

    // HOOKS
    const { promiseInProgress } = usePromiseTracker({ area });

    // EVENTS
    const resizeObserver = window.ResizeObserver && new window.ResizeObserver(() => setLoaderStyles());

    // EFFECTS
    React.useLayoutEffect(() => { // In case the window was resized or loading / promise was changed

        //console.log("Loader useEffect")
        loaderEl.current && setLoaderStyles();

    }, [loading, promiseInProgress])

    React.useLayoutEffect(() => {

        loaderSize && document.documentElement.style.setProperty('--loader-size', loaderSize + "px");
        loaderColor && document.documentElement.style.setProperty('--loader-color', loaderColor);

        loaderEl.current && resizeObserver && resizeObserver.observe(loaderEl.current);

        return () => {
            resizeObserver && resizeObserver.unobserver && resizeObserver.unobserver(loaderEl.current);
        }

    }, [])

    const getChildrenStyles = () => {
        //let childrenHeight = 0;
        let childrenStyles = {};

        const childrenArray = React.Children.toArray(children);

        if (children && childrenArray.length === 1) {
            // childrenHeight = loaderEl.current && loaderEl.current.nextSibling && loaderEl.current.nextSibling.clientHeight || 0;
            childrenStyles = loaderEl.current && loaderEl.current.nextSibling && getComputedStyle(loaderEl.current.nextSibling) || {};
        }
        else if (children && childrenArray.length > 1) {
            //childrenHeight = loaderEl.current.parentNode.clientHeight || 0;
            childrenStyles = loaderEl.current && getComputedStyle(loaderEl.current.parentElement) || {};
        }

        return childrenStyles;
    }

    const setLoaderStyles = () => {

        const childrenStyles = getChildrenStyles();

        if (areaEl.current) {

            areaEl.current.style.height = childrenStyles.height;
            areaEl.current.style.width = childrenStyles.width;
            areaEl.current.style.borderRadius = childrenStyles.borderRadius;
            areaEl.current.style.overflow = 'hidden';
            areaEl.current.style.opacity = 1;

            if (parseInt(childrenStyles.padding, 10) > 0) { /* Convert padding to negative margin */

                areaEl.current.style.marginTop = -1 * parseInt(childrenStyles.paddingTop, 10) + 'px';
                areaEl.current.style.marginBottom = -1 * parseInt(childrenStyles.paddingBottom, 10) + 'px';
                areaEl.current.style.marginLeft = -1 * parseInt(childrenStyles.paddingLeft, 10) + 'px';
                areaEl.current.style.marginRight = -1 * parseInt(childrenStyles.paddingRight, 10) + 'px';
            }

            if (parseInt(childrenStyles.height, 10) > window.innerHeight) { // If children heigth is larger than viewport height
                setLoaderType("Fixed");
            }
            else {
                setLoaderType("Absolute");
            }
        }
    }

    const renderWrapperWithAbsoluteLoader = () => {
        return <div className={`loader-container`} ref={loaderEl}>
            <div className={`loader-area ${withMasking ? "loader-mask" : ""}`} ref={areaEl}></div>
        </div>
    }

    const renderWrapperWithFixedLoader = () => {

        //const sideBarWidth = document.querySelector(".main-sidebar")?.clientWidth;
        const sideBarWidth = document.querySelector(".main-sidebar") && document.querySelector(".main-sidebar").clientWidth;

        return <div className={`loader-container`} ref={loaderEl}>
            <div
                className={`loader-area loader-fixed ${sideBarWidth > 0 ? "with-sidebar" : ""} ${withMasking ? "loader-mask" : ""}`}
                ref={areaEl}>
            </div>
        </div>
    }

    const renderFullScreenLoader = () => {
        return <div className={`full-screen-loader`}></div>
    }

    // const renderChildrenWithRef = () => {
    //     React.Children.toArray(children);
    // }

    // const isExceedViewPort = window.innerHeight > (childrenRef.current ? getComputedStyle(childrenRef.current) : 0);

    const isActive = promiseInProgress || loading; // for debug set to true
    //console.log("loaderType=", loaderType);

    return (

        <React.Fragment>
            {isActive && children && (loaderType === "Fixed" ? renderWrapperWithFixedLoader() : renderWrapperWithAbsoluteLoader())}
            {isActive && !children && ReactDOM.createPortal(renderFullScreenLoader(), document.body)}
            {children}
            {/* {React.cloneElement(children, null, ref)} */}
            {/* { React.createElement(React.Fragment, {}, React.Children.toArray(children))} */}
            {/* {React.cloneElement( React.Children.toArray(children)[0], {})} */}
            {/* { React.createElement(React.Fragment, {},
                React.Children.map(children,
                    (child => React.cloneElement(child, { })
                    )
                )
            )
            } */}
        </React.Fragment>
    );

};

export default DMLoader;