import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import ErrorImg from '../../assets/Images/depression-illustration.jpg'

import './ErrorPage.scss';

const ErrorPage = () => {
    const { state } = useLocation();
    
    return (
        <div className='errorp-container'>
            <div className='right-page'>
                <div className='error-code'>ERROR CODE: {state && state.errorCode && state.errorCode.toString() }</div>
                <div className='oops'>Oops!!</div>
                <div className='err-desc'>Error occurred, please contact us...</div>
                <div className='enabled-links-wrapper'>
                    <span>Here are some helpful links instead:</span>
                    <div className='links-list'>
                        <Link className='link' to='/'>Galley</Link>
                        <Link className='link' to='/orders/pending'>Pending Orders</Link>
                    </div>
                </div>
            </div>
            <div className='left-page'>
                <img className='error-img' src={ErrorImg} alt='error' />
            </div>
        </div>
    )
};

export default ErrorPage;
