import { atom } from 'recoil';

export const appStoreSettings = atom({
    key: 'appStoreSettings',
    default: {
        theme: '',
        brandName: {
            line1: '',
            line2: ''
        },
        brandLogo: '',
        activeStep: null, // 0 = nameStep active, 1 = logoStep active
        error: null,
        storeName: '',
        storeLocation: '',
        storeInitials: ''
    }
});

export const originalStoreSettingsState = atom({
    key: 'originalStoreSettingsState',
    default: {
        theme: '',
        brandName: {
            line1: '',
            line2: ''
        },
        brandLogo: '',
        storeName: '',
        storeLocation: '',
        storeInitials: ''
    }
});

export const isVisibleState = atom({
    key: 'isVisibleState',
    default: false
});