import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotFound404 from '../../assets/Images/NotFond404.jpg';

import './PageNotFound.scss';

const PageNotFound = () => {
    const navigateTo = useNavigate();

    return (
        <div className="ds-page-not-found-contener">
            <div className='page-left'>
                <img className='not-found-img' src={NotFound404} alt='not-found' />
            </div>
            <div className='page-right'>
                <div>ERROR!</div>
                <div className='not-fonud-text'>PAGE NOT FOUND</div>
                <div className="ds-btn-home" onClick={() => navigateTo('/')}>
                    GO TO HOME PAGE
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;